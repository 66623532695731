* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #eeeeee;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 36px;
  background: transparent;
  border: 1px solid #fff;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider-absolute-horizontal {
  position: fixed;
  background: #000000;
  top: 15%;
  left: 50%;
  width: 30%;
  transform: translate(-50%, -50%);
}

.slider-container {
  position: relative;
  width: 100%;
}

.slider-container::before {
  content: "Wielkość";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5); /* Slightly transparent white text */
  pointer-events: none; /* Ensures the slider remains clickable */
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #04aa6d;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04aa6d;
  cursor: pointer;
}

::placeholder {
  color: #ffffff;
  opacity: 0.5;
}
